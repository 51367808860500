define("lh4/constants/launch-control/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.LIMITED_NAVIGATION_ROUTES = _exports.PROGRESS_CIRCUMFERENCE = _exports.MODULE = _exports.SIGN_UP_MODAL_TEXT = _exports.DELETE_MODAL_TEXT = _exports.SAVE_MODAL_TEXT = _exports.SKIP_MODAL_TEXT = _exports.CONFIRMATION_MODAL_TEXT = _exports.ERRORS = void 0;
  var ERRORS = Object.freeze({
    serverError: 'An error occured on our side, please try again later.'
  }); // https://shift4.atlassian.net/browse/LH-28771 DELETE CONFIRMATION_MODAL_TEXT when save modal will be replaced in all modules

  _exports.ERRORS = ERRORS;
  var CONFIRMATION_MODAL_TEXT = Object.freeze({
    save: 'SAVE',
    dontSave: 'DON\'T SAVE',
    exit: 'EXIT',
    cancel: 'CANCEL',
    delete: 'DELETE',
    saveSetupText: 'Do you want to save changes?',
    exitSetupText: 'Do you want to exit?',
    saveTitle: 'Save Changes',
    exitTitle: 'Exit Setup',
    yes: 'YES',
    subText: 'You can set this up later at the end.',
    skipModuleTitle: 'Are you sure?',
    skipTextSTO: 'Restaurants that sign up for Online Ordering could typically see 20% boost in revenue.',
    skipTextGiftCards: 'Restaurants that sign up for Gift Cards typically see 10% to 30% boost in revenue.',
    skipTextLoyalty: 'Restaurants that sign up for Loyalty typically see 5% to 20% boost in revenue.',
    skipTextSkytabBi: 'Restaurants that sign up for Skytab BI typically see 20% to 30% boost in revenue.',
    skipTextTableLayout: 'Restaurants that sign up for Table Layout typically see 10% to 15% boost in revenue.',
    skipTextWebsiteBuilder: 'Restaurants that sign up for Website Builder typically see 15% to 25% boost in revenue.',
    skipTextInvoices: 'Restaurants that sign up for Invoices typically see up to 10% boost in revenue.',
    kioskMessage: 'If you leave now, your order won\'t be placed. Please finish the remaining setup steps to place your order.'
  });
  _exports.CONFIRMATION_MODAL_TEXT = CONFIRMATION_MODAL_TEXT;
  var SKIP_MODAL_TEXT = Object.freeze({
    title: 'Are you sure?',
    subContent: 'You can set this up later at the end.',
    confirm: 'YES',
    discard: 'CANCEL'
  });
  _exports.SKIP_MODAL_TEXT = SKIP_MODAL_TEXT;
  var SAVE_MODAL_TEXT = Object.freeze({
    title: 'Save Changes',
    content: 'Do you want to save changes?',
    confirm: 'SAVE',
    discard: 'DON\'T SAVE'
  });
  _exports.SAVE_MODAL_TEXT = SAVE_MODAL_TEXT;
  var DELETE_MODAL_TEXT = Object.freeze({
    title: 'Are you sure?',
    content: 'This action cannot be undone',
    confirm: 'DELETE',
    discard: 'CANCEL'
  });
  _exports.DELETE_MODAL_TEXT = DELETE_MODAL_TEXT;
  var SIGN_UP_MODAL_TEXT = Object.freeze({
    skipTextSTO: 'Restaurants that sign up for Online Ordering could typically see 20% boost in revenue.',
    skipTextIntegrations: 'Restaurants that sign up for Integrations typically see 10% to 20% boost in revenue.',
    skipTextGiftCards: 'Restaurants that sign up for Gift Cards typically see 10% to 30% boost in revenue.',
    skipTextLoyalty: 'Restaurants that sign up for Loyalty typically see 5% to 20% boost in revenue.',
    skipTextSkytabBi: 'Restaurants that sign up for Skytab BI typically see 20% to 30% boost in revenue.',
    skipTextTableLayout: 'Restaurants that sign up for Table Layout typically see 10% to 15% boost in revenue.',
    skipTextWebsiteBuilder: 'Restaurants that sign up for Website Builder typically see 15% to 25% boost in revenue.',
    skipTextInvoices: 'Restaurants that sign up for Invoices typically see up to 10% boost in revenue.',
    kioskMessage: 'If you leave now, your order won\'t be placed. Please finish the remaining setup steps to place your order.'
  });
  _exports.SIGN_UP_MODAL_TEXT = SIGN_UP_MODAL_TEXT;
  var MODULE = Object.freeze({
    WEBSITE_BUILDER: 'Website',
    SKYTAB_BI: 'Business Intelligence'
  });
  _exports.MODULE = MODULE;
  var PROGRESS_CIRCUMFERENCE = 439.6;
  _exports.PROGRESS_CIRCUMFERENCE = PROGRESS_CIRCUMFERENCE;
  var LIMITED_NAVIGATION_ROUTES = Object.freeze(['authenticated.launch-control.progress', 'authenticated.launch-control.restaurant-basics', 'authenticated.launch-control.menu', 'authenticated.launch-control.installation-details', 'authenticated.account-maintenance', 'authenticated.add-on-devices.customer-display', 'authenticated.add-on-devices.gpatt', 'authenticated.add-on-devices.printers', 'authenticated.add-on-devices.tableside', 'authenticated.dashboard', 'authenticated.deposit-breakdown.index', 'authenticated.device-management.index', 'authenticated.devices', 'authenticated.fundraising', 'authenticated.gift-cards', 'authenticated.kitchen-display-system.order-ready-screen', 'authenticated.kitchen-display-system.preparable-items', 'authenticated.kitchen-display-system.preparation-times', 'authenticated.launch-control.index', 'authenticated.orders', 'authenticated.reporting.fiscal-reporting', 'authenticated.reporting.gift-card-reporting', 'authenticated.reporting.reports', 'authenticated.reporting.subscriptions.index', 'authenticated.reporting', 'authenticated.settings.2fa', 'authenticated.settings.2fa.index', 'authenticated.settings.account', 'authenticated.settings.locations', 'authenticated.settings.reporting-settings', 'authenticated.settings.users', 'authenticated.settings.users.index', 'authenticated.settings', 'authenticated.solo.devices.index', 'authenticated.solo.items.index', 'authenticated.solo.settings', 'authenticated.solo.taxes.index', 'authenticated.support', 'authenticated.transactions', 'authenticated.website-builder', 'authenticated.epro.invoices.landing-page']);
  _exports.LIMITED_NAVIGATION_ROUTES = LIMITED_NAVIGATION_ROUTES;
});