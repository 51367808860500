define("lh4/templates/authenticated/onboarding-wizard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "11OwN8t7",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[5,\"ui/modal/s4-multistep-fullscreen-modal\",[],[[\"@title\",\"@onClose\",\"@errorMessage\",\"@data-dd-action-name\",\"@isLoading\",\"@firstStep\",\"@currentStep\",\"@totalSteps\",\"@onBack\"],[\"placeholder\",[22,\"exitSetup\"],[22,\"errorMessage\"],\"placeholder\",[22,\"isLoading\"],0,[22,\"currentStep\"],2,[22,\"onBack\"]]],{\"statements\":[[0,\"\\n\\t\"],[6,[23,1,[\"content\"]],[],[[],[]],{\"statements\":[[0,\"\\n\\t\"]],\"parameters\":[]}],[0,\"\\n\\t\"],[6,[23,1,[\"actions\"]],[],[[],[]],{\"statements\":[[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"button-section\"],[8],[0,\"\\n\\t\\t\\t\"],[5,\"ui/s4/buttons/button\",[],[[\"@type\",\"@text\",\"@onClick\",\"@data-dd-action-name\"],[\"rounded-contained\",\"NEXT\",[22,\"onNext\"],\"placeholder\"]]],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/onboarding-wizard.hbs"
    }
  });

  _exports.default = _default;
});